import { en } from "./en";
import { ko } from "./ko";
import { cn } from "./cn";

const resources = {
  en,
  ko,
  cn,
};

export default resources;

import React from "react";
import Title from "components/Title";
import { useTranslation } from "react-i18next";
import SectionContainer from "components/SectionContainer";
import BiFiX from "./section-products/BiFiX";
import ChainRunners from "./section-products/ChainRunners";
import BiPort from "./section-products/BiPort";
import useIsMobile from "plugins/useIsMobile";
import BiFrost from "./section-products/BiFrost";
import BiFi from "./section-products/BiFi";

const Products: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <SectionContainer paddingTop={isMobile ? "100px" : "150px"}>
      <Title id="products-scroll-view" title={t("products.header.title")} />
      <BiFrost />
      <BiFi />
      <BiFiX />
      <ChainRunners />
      <BiPort />
    </SectionContainer>
  );
};

export default Products;

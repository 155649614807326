import React from "react";
import styled from "styled-components";
import TextWrapper from "./TextWrapper";
import logo from "assets/images/chainrunners.svg";
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from "config/mediaQuery";
import transition from "plugins/styles/transition";
import Background from "./Background";
import { linearGradientGreen } from "plugins/styles/color";
import useIsMobile from "plugins/useIsMobile";
import ProductContainer from "./ProductContainer";

const ImageWrapper = styled.div`
  margin-top: 200px;
  padding-right: 100px;
  z-index: 2;
  ${transition}

  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    margin-top: 0;
    margin-left: 0;
    margin-right: 20px;
    padding-right: 0;
  }
`;

const ChainRunners: React.FC = () => {
  const isMobile = useIsMobile();

  return (
    <ProductContainer>
      <ImageWrapper>
        <img
          width={isMobile ? "120px" : "220px"}
          height="auto"
          src={logo}
          alt="Chainunners"
        />
      </ImageWrapper>
      <TextWrapper index={3} marginTop={isMobile ? "0" : "90px"} />
      <Background color={linearGradientGreen} skew={-8} />
    </ProductContainer>
  );
};

export default ChainRunners;

export const telegram = 'https://t.me/Bifrost_Global';
export const twitter = 'https://twitter.com/BIFROSTio';
export const discord = 'https://discord.gg/9C5KQEacAh';
export const medium =
  'https://medium.com/bifrost/how-to-buy-bfc-and-bifi-a21bcb9749a9';
export const youtube = 'https://www.youtube.com/c/Bifrost_BFC';
export const github = 'https://github.com/bifrost-platform';
export const linkTree = 'https://linktr.ee/bifrost_network';

export const bifrostPaperEng =
  'https://assets.thebifrost.io/whitepaper/Bifrost_WP_Eng.pdf';
export const bifrostPaperKor =
  'https://assets.thebifrost.io/whitepaper/Bifrost_WP_Kor.pdf';
export const bifiPaper =
  'https://assets.thebifrost.io/whitepaper/BiFi-White-Paper-V1.0-EN.pdf';

export const linearGradientNavy =
  "linear-gradient(278.78deg, #335F84 3.64%, rgba(51, 95, 132, 0) 92.85%)";
export const linearGradientGreen =
  "linear-gradient(278.78deg, rgba(54, 164, 166, 0) 3.64%, #36A4A6 92.85%)";
export const linearGradientBlue =
  "linear-gradient(278.78deg, #379ef3 3.64%, rgba(55, 158, 243, 0) 92.85%)";
export const linearGradientMint =
  "linear-gradient(278.78deg,rgba(71, 202, 204, 0) 3.64%,#47cacc 92.85%)";
export const linearGradientPurple =
  "linear-gradient(278.78deg, #a5a6f6 3.64%, rgba(165, 166, 246, 0) 92.85%)";

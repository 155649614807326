import React from "react";
import Text from "./Text";
import useIsMobile from "plugins/useIsMobile";

interface TitleProps {
  id?: string;
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
}

const Title: React.FC<TitleProps> = ({ id, title, description }) => {
  const isMobile = useIsMobile();

  return (
    <div id={id} style={{ paddingTop: "50px", zIndex: 2 }}>
      <Text size={isMobile ? "40px" : "60px"} weight="bold" align="left">
        {title}
      </Text>
      {description && (
        <Text size={isMobile ? "20px" : "34px"} weight="medium">
          {description}
        </Text>
      )}
    </div>
  );
};

export default Title;

import React from "react";
import Title from "components/Title";
import { useTranslation } from "react-i18next";
import SectionContainer from "components/SectionContainer";
import useIsMobile from "plugins/useIsMobile";
import Text from "../../components/Text";

const Mission: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <SectionContainer paddingTop={isMobile ? "180px" : "300px"}>
      <Title
        title={t("mission.title")}
        description={t("mission.subTitle")
          .split("\n")
          .map((line, lineIndex) => (
            <div key={lineIndex}>{line}</div>
          ))}
      />
      <Text
        size={isMobile ? "16px" : "24px"}
        marginTop="20px"
        weight="bold"
        color="gray"
      >
        {t("mission.description")
          .split("\n")
          .map((line, lineIndex) => (
            <div key={lineIndex}>{line}</div>
          ))}
      </Text>
    </SectionContainer>
  );
};

export default Mission;

import React from "react";
import styled from "styled-components";
import transition from "plugins/styles/transition";

interface TextProps {
  children: string | React.ReactNode;
  size: string;
  color?: string;
  align?: "left" | "right";
  weight?: "bold" | "medium" | "thin";
  marginTop?: string;
  marginRight?: string;
  marginBottom?: string;
}

const Root = styled.div<{
  size: string;
  color?: string;
  align?: string;
  weight?: string;
  marginTop?: string;
  marginRight?: string;
  marginBottom?: string;
}>`
  text-align: ${(props) => (props.align === "right" ? "right" : "left")};
  font-size: ${(props) => props.size};
  font-family: "Roboto", sans-serif;
  color: ${(props) => props.color || "white"};
  ${transition}
  ${(props) => (props.weight === "bold" ? `font-weight: 900` : "")};
  ${(props) => (props.weight === "medium" ? `font-weight: 500` : "")};
  ${(props) => (props.weight === "thin" ? `font-weight: 300` : "")};
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop};` : "")}
  ${(props) => (props.marginRight ? `margin-right: ${props.marginRight};` : "")}
  ${(props) =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom};` : ""}
`;

const Text: React.FC<TextProps> = ({
  children,
  size,
  color,
  align,
  weight,
  marginTop,
  marginRight,
  marginBottom,
}) => {
  return (
    <Root
      size={size}
      color={color}
      align={align}
      weight={weight}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
    >
      {children}
    </Root>
  );
};

export default Text;

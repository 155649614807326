export const en = {
  header: {
    products: "Products",
    media: "Media",
    join: "Careers",
  },
  about: {
    title:
      "A research-oriented Blockchain \n building portfolio of DApps \n for DeFi, NFTs, and GameFi",
    subTitle:
      "Always security first, user first - seamlessly \n integrating real-world with the metaverse.",
  },
  mission: {
    title: "Mission",
    subTitle:
      "Bridging values from scattered networks to build real, tangible and flexible use cases in blockchain with multichain BIFROST infrastructure.",
    description:
      "Our team believes in the power of mass adoption in decentralization through killer DApp use cases across multiple blockchain networks.",
  },
  products: {
    header: {
      title: "Products",
      description: "we design something spectacular \n that we are planning",
    },
    body: [
      {
        title: "BIFROST",
        subTitle: "Something Amazing",
        description:
          "Full developer suite for multichain DApp development, liberating blockchain developers from currently constrained infrastructure for specific blockchain networks.",
        link: "https://thebifrost.io/",
      },
      {
        title: "BiFi",
        subTitle: "Something Amazing",
        description:
          "Decentralized multichain DeFi lending protocol built on BIFROST multichain - supporting Ethereum, Binance Smart Chain, Avalanche, and Klyatn networks. Our Ethereum network even services Native-BTC lending.",
        link: "https://bifi.finance/",
      },
      {
        title: "BiFi X",
        subTitle: "Something Amazing",
        description:
          "DeFi's 1st automated one-click multichain leverage platform run by flashloans, saving user transaction fees and time for yield farming and margin trading.",
        link: "https://x.bifi.finance/",
      },
      {
        title: "ChainRunners",
        subTitle: "Something Amazing",
        description:
          "Smart integration software engine for customizable blockchain strategies, with use cases such as Chainrunner Q, an automated pre-built scenario DeFi aggregator.",
        link: "https://q.chainrunner.io/",
      },
      {
        title: "Biport",
        subTitle: "Something Amazing",
        description:
          "Combined decentralized wallet available as a Chrome extension, bridging multichain networks across any DApps, DeFi, NFTs, and GameFi. Best optimized for BiFi.",
        link: "https://biport.io/",
      },
    ],
  },
  media: {
    header: {
      title: "Media",
      description:
        "you can find us on media \n we're at the center of the Defi platform",
    },
  },
  join: {
    header: {
      title: "Careers",
      description: "email to hello@pilab.co",
    },
  },
  footer: {
    email: "info@pilab.co",
    address: {
      seoul:
        "Seoul address: 5, Seongsuil-ro 8-gil, Seongdong-gu, Seoul, Republic of Korea",
      singapore:
        "Singapore address: 16 Collyer Quay #17-00 Income Raffles, Singapore",
    },
    bifrostpaper: "BIFROST White Paper",
    bifipaper: "BiFi White Paper",
    copyright: "Copyright {{0}} PiLab Technology",
  },
  more: "→ FIND OUT MORE",
};

import React from "react";
import styled from "styled-components";
import logo from "assets/images/bifiX.svg";
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from "config/mediaQuery";
import TextWrapper from "./TextWrapper";
import transition from "plugins/styles/transition";
import Background from "./Background";
import { linearGradientBlue } from "plugins/styles/color";
import useIsMobile from "plugins/useIsMobile";
import ProductContainer from "./ProductContainer";

const ImageWrapper = styled.div`
  margin-top: 190px;
  padding-left: 100px;
  z-index: 2;
  ${transition}

  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    margin-top: 0;
    padding-left: 0;
  }
`;

const BiFiX: React.FC = () => {
  const isMobile = useIsMobile();
  return (
    <ProductContainer reverse>
      <TextWrapper index={2} marginTop={isMobile ? "0" : "60px"}/>
      <ImageWrapper>
        <img
          width={isMobile ? "200px" : "300px"}
          height="auto"
          src={logo}
          alt="BIFI X"
        />
      </ImageWrapper>
      <Background color={linearGradientBlue} skew={8} />
    </ProductContainer>
  );
};

export default BiFiX;

import React from "react";
import AppHeader from "components/AppHeader";
import AppContainer from "components/AppContainer";
import About from "./section-home/About";
import Products from "./section-home/Products";
import Media from "./section-home/Media";
import Join from "./section-home/Join";
import AppFooter from "components/AppFooter";
import Mission from "./section-home/Mission";

const Home: React.FC = () => {
  return (
    <AppContainer>
      <AppHeader />
      <About />
      <Mission />
      <Products />
      <Media />
      <Join />
      <AppFooter />
    </AppContainer>
  );
};

export default Home;

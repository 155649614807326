import React from "react";
import styled from "styled-components";
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from "../../../config/mediaQuery";

interface ProductContainerProps {
  children: React.ReactNode;
  marginTop?: string;
  reverse?: boolean;
}

const Root = styled.div<{ reverse?: boolean; marginTop?: string }>`
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => props.marginTop || "220px"};

  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    flex-direction: ${(props) => (props.reverse ? "column-reverse" : "column")};
    justify-content: center;
    margin-top: ${(props) => props.marginTop || "210px"};
  }
`;

const ProductContainer: React.FC<ProductContainerProps> = ({
  children,
  reverse,
  marginTop,
}) => {
  return (
    <Root reverse={reverse} marginTop={marginTop}>
      {children}
    </Root>
  );
};

export default ProductContainer;

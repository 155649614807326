import React from "react";
import MediaCard from ".//MediaCard";
import styled from "styled-components";
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from "config/mediaQuery";
import ImageList from "@mui/material/ImageList";
import useIsMobile from "plugins/useIsMobile";
import transition from "plugins/styles/transition";

const data = [
  {
    articleUrl:
      "http://www.digitaltoday.co.kr/news/articleView.html?idxno=486297",
    imgUrl: require("assets/images/media-1.png"),
    title: "블록체인 기술 기업 파이랩, 쟈스미와 협력해 일본 진출",
    date: "2023.08.29",
  },
  {
    articleUrl: "https://www.datanet.co.kr/news/articleView.html?idxno=185453",
    imgUrl: require("assets/images/media-2.png"),
    title:
      "파이랩·서티라이프, 의료기기 정품 인증·디지털 보증서 발행 시스템 구축",
    date: "2023.07.19",
  },
  {
    articleUrl: "https://www.news1.kr/articles/5097076",
    imgUrl: require("assets/images/media-3.png"),
    title:
      "파이랩테크놀로지, 한국정보인증과 네임서비스 기반 웹3 인프라 구축한다",
    date: "2023.07.04",
  },
  {
    articleUrl: "http://www.datanet.co.kr/news/articleView.html?idxno=180528",
    imgUrl: require("assets/images/media-5.png"),
    title: "파이랩, 메인넷 ‘바이프로스트 네트워크’ 정식 가동",
    date: "2023.01.30",
  },
  {
    articleUrl:
      "https://apnews.com/press-release/pr-newswire/technology-blockchain-51ff53ca2e6b7f742a9d6ea1fc799a47",
    imgUrl: require("assets/images/media-4.png"),
    title:
      "PiLab Technology (BIFROST) Announces A $57 Million Eco-Fund to Expand Its Blockchain Ecosystem",
    date: "2022.03.24",
  },
  {
    articleUrl:
      "https://www.prnewswire.com/news-releases/pilab-technology-bifrost-raises-8-4m-series-a[…]-korea-investment-partners-and-stic-ventures-301484661.html",
    imgUrl: require("assets/images/media-6.png"),
    title:
      "PiLab Technology (BIFROST) Raises $8.4M Series A Funding Round Led by Korea Investment Partners and STIC Ventures",
    date: "2022.02.17",
  },
];

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  margin-top: 20px;
  ${transition}

  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    margin-top: 30px;
  }
`;

const Wrapper = styled(ImageList)`
  display: flex;
  flex-direction: row;

  ::-webkit-scrollbar {
    display: block;
    background-color: #1c1f27;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #353b4a;
    border-radius: 10px;
    margin-left: 20px;
  }
`;

const MediaCardWrapper: React.FC = () => {
  const isMobile = useIsMobile();

  return (
    <Root>
      <Wrapper cols={isMobile ? 6 : 3}>
        {data.map((item, index) => (
          <MediaCard
            articleUrl={item.articleUrl}
            imgUrl={item.imgUrl}
            title={item.title}
            date={item.date}
            key={item.imgUrl}
          />
        ))}
      </Wrapper>
    </Root>
  );
};

export default MediaCardWrapper;

import React from "react";
import styled from "styled-components";
import transition from "plugins/styles/transition";

interface SectionContainerProps {
  children?: React.ReactNode;
  paddingTop?: string;
}

const Root = styled.div<{ paddingTop?: string }>`
  display: flex;
  flex-direction: column;
  height: auto;
  ${(props) => (props.paddingTop ? `margin-top: ${props.paddingTop};` : "")}
  width: 90%;
  max-width: 1440px;
  ${transition}
`;

const SectionContainer: React.FC<SectionContainerProps> = ({
  children,
  paddingTop,
}) => {
  return <Root paddingTop={paddingTop}>{children}</Root>;
};

export default SectionContainer;

import React from "react";
import styled from "styled-components";
import TextWrapper from "./TextWrapper";
import logo from "assets/images/bifi.svg";
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from "config/mediaQuery";
import transition from "plugins/styles/transition";
import Background from "./Background";
import { linearGradientMint } from "plugins/styles/color";
import useIsMobile from "plugins/useIsMobile";
import Link from "components/Link";
import { useTranslation } from "react-i18next";
import ProductContainer from "./ProductContainer";

const ImageWrapper = styled.div`
  margin-top: 200px;
  margin-right: 150px;
  z-index: 2;
  ${transition}

  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    margin-top: 0;
    margin-left: 0;
    margin-right: 20px;
  }
`;

const BiFi: React.FC = () => {
  const isMobile = useIsMobile();

  return (
    <ProductContainer>
      <ImageWrapper>
        <img
          width={isMobile ? "120px" : "220px"}
          height="auto"
          src={logo}
          alt="ChainRunners"
        />
      </ImageWrapper>
      <TextWrapper index={1} marginTop={isMobile ? "0" : "60px"} />
      <Background color={linearGradientMint} skew={-8} zIndex={0} />
    </ProductContainer>
  );
};

export default BiFi;

import React from "react";
// import logo from "assets/images/logoFooter.svg";
import logo from "assets/images/logo.svg";
import linktree from "assets/images/linktree_icon.svg";
import styled from "styled-components";
import * as link from "assets/links";
import { useTranslation } from "react-i18next";
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from "config/mediaQuery";
import useIsMobile from "plugins/useIsMobile";
import Link from "./Link";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
  max-width: 1440px;
`;

const Divider = styled.hr`
  width: 85%;
  height: 0;
  border: 1px solid gray;
  opacity: 0.35;
`;

const Logo = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div<{ marginTop: string }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
  height: auto;
  margin-top: ${(props) => props.marginTop};

  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    flex-direction: column;
    align-items: center;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    margin-top: 40px;
    width: 70%;
  }
`;

const TextWrapper = styled.div<{
  width?: string;
  row?: boolean;
  marginRight?: string;
  align?: string;
}>`
  display: flex;
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  ${({ width }) => (width ? `width: ${width};` : "")};
  ${({ marginRight }) => (marginRight ? `margin-right: ${marginRight};` : "")}
  ${({ align }) => (align ? `text-align: ${align};` : "")}
  padding-bottom: 20px;

  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    width: 75%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
  }
`;

const Text = styled.div<{
  marginRight?: string;
  marginLeft?: string;
  opacity?: number;
}>`
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  padding-bottom: 10px;
  color: white;
  opacity: ${({ opacity }) => (opacity ? opacity : 0.35)};
  ${({ marginRight }) => (marginRight ? `margin-right: ${marginRight};` : "")};
  ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft};` : "")};

  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    line-height: 26px;
    font-size: 11px;
  }
`;

const AppFooter: React.FC = () => {
  const { t } = useTranslation();
  const currentLanguage =
    localStorage.getItem("language") || navigator.language || "en";
  const isMobile = useIsMobile();
  const logoWidth = isMobile ? "30px" : "35px";

  return (
    <Root>
      <Divider />
      <Wrapper marginTop="30px">
        <img
          height="26px"
          width={isMobile ? "340px" : "auto"}
          src={logo}
          alt="PiLab"
        />
        <LogoWrapper>
          <Link href={link.linkTree}>
            <Logo width={logoWidth} src={linktree} alt="linktree" />
          </Link>
        </LogoWrapper>
      </Wrapper>
      <Wrapper marginTop="70px">
        <TextWrapper>
          <Text
            marginRight={isMobile ? "0" : "20px"}
            opacity={isMobile ? 1 : 0.35}
          >
            {t("footer.address.seoul")}
          </Text>
          <Text opacity={isMobile ? 1 : 0.35}>
            {t("footer.address.singapore")}
          </Text>
        </TextWrapper>
        <TextWrapper align="right">
          <Text>hello@pilab.co</Text>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <Link
              href={
                currentLanguage === "ko"
                  ? link.bifrostPaperKor
                  : link.bifrostPaperEng
              }
              underline
            >
              <Text marginRight={isMobile ? "0px" : "12px"}>
                {t("footer.bifrostpaper")}
              </Text>
            </Link>
            <Link href={link.bifiPaper} underline>
              <Text>{t("footer.bifipaper")}</Text>
            </Link>
          </div>
          <Text>{t("footer.copyright", [
            new Date().getFullYear(),
          ])}</Text>
        </TextWrapper>
      </Wrapper>
    </Root>
  );
};

export default AppFooter;

import React from "react";
import Title from "components/Title";
import { useTranslation } from "react-i18next";
import MediaCardWrapper from "./section-media/MediaCardWrapper";
import SectionContainer from "components/SectionContainer";

const Media: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SectionContainer paddingTop="200px">
      <Title id="media-scroll-view" title={t("media.header.title")} />
      <MediaCardWrapper />
    </SectionContainer>
  );
};

export default Media;

import React from "react";
import styled from "styled-components";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import useIsMobile from "plugins/useIsMobile";
import Link from "components/Link";

interface TextWrapperProps {
  index: number;
  marginTop?: string;
}

const Root = styled.div<{ marginTop?: string }>`
  z-index: 2;
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop};` : "")}
`;

const TextWrapper: React.FC<TextWrapperProps> = ({ index, marginTop }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Root marginTop={marginTop}>
      <Text
        size={isMobile ? "40px" : "40px"}
        weight="bold"
        marginTop={isMobile ? "50px" : "70px"}
      >
        {t(`products.body.${index}.title`)}
      </Text>
      <Text
        size={isMobile ? "20px" : "24px"}
        weight="thin"
        marginTop="20px"
        marginBottom="10px"
      >
        {t(`products.body.${index}.description`)
          .split("\n")
          .map((line, lineIndex) => (
            <div key={lineIndex}>{line}</div>
          ))}
      </Text>
      <Link href={t(`products.body.${index}.link`)} underline>
        {t("more")}
      </Link>
    </Root>
  );
};

export default TextWrapper;

import React from "react";
import styled from "styled-components";
import SectionContainer from "components/SectionContainer";
import logo from "assets/images/join.svg";
import Title from "components/Title";
import { useTranslation } from "react-i18next";
import transition from "plugins/styles/transition";
import {
  MEDIA_QUERY_WIDTH_DESKTOP,
  MEDIA_QUERY_WIDTH_MOBILE_WIDTH,
} from "config/mediaQuery";
import useIsMobile from "plugins/useIsMobile";

const Background = styled.img`
  position: absolute;
  width: 100%;
  align-self: center;
  max-width: 1920px;

  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    padding-top: 0px;
  }
`;

const ContactButton = styled.a`
  display: flex;
  margin: 63% 30% 40% 30%;
  z-index: 3;
  background: linear-gradient(87.86deg, #f9b10b 4.83%, #379ef3 96.57%);
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  border: 0;
  width: 40%;
  max-width: 480px;
  height: 90px;
  font-size: 42px;
  font-weight: 800;
  color: white;
  text-decoration: none;
  ${transition}

  &:active {
    opacity: 0.7;
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP}) {
    margin-top: 70%;
  }
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    margin-top: 57%;
    font-size: 15px;
    height: 50px;
  }
`;

const Join: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <SectionContainer paddingTop={isMobile ? "190px" : "200px"}>
      <Title id="join-scroll-view" title={t("join.header.title")} />
      <ContactButton href="mailto:hello@pilab.co">Contact us</ContactButton>
      <Background src={logo} alt="join PiLab" />
    </SectionContainer>
  );
};

export default Join;

export const cn = {
  header: {
    products: "Products",
    media: "Media",
    join: "Careers",
  },
  about: {
    title: "构建涵盖 DeFi、NFT 和 GameFi \n 的全面区块链 DApp 产品系列",
    subTitle: "Pilab将安全放在首位",
  },
  mission: {
    title: "Mission",
    subTitle:
      "Pilab团队旨在通过BIFROST的多链技术连接分散的各个网络，以构建真实存在的灵活用例。",
    description:
      "我们团队坚信，实现去中心化应用的大规模采用的关键在于保障安全的杀手级多链用例的开发及实现。",
  },
  products: {
    header: {
      title: "Products",
      description: "we design something spectacular \n that we are planning",
    },
    body: [
      {
        title: "BIFROST",
        subTitle: "Something Amazing",
        description:
          "BIFROST为多链DApp开发提供完整开发工具套件，打破当前受限于特定主网的区块链开发环境，以多链组合开发环境支持杀手级DApp的诞生。",
        link: "https://thebifrost.io/",
      },
      {
        title: "BiFi",
        subTitle: "Something Amazing",
        description:
          "BiFi是基于BIFROST的去中心化多链DeFi存贷款服务。 目前支持以太坊、币安智能链、Avalanche、Klaytn网络。 以独家多练技术，BiFi的以太坊网络甚至支持Native BTC的直接存贷款。",
        link: "https://bifi.finance/",
      },
      {
        title: "BiFi X",
        subTitle: "Something Amazing",
        description:
          "全球首项DeFi的一键式多链自动化杠杆平台，通过闪贷技术运行，大大节省了收益耕种和保证金交易上的交易手续费及繁杂过程",
        link: "https://x.bifi.finance/",
      },
      {
        title: "ChainRunners",
        subTitle: "Something Amazing",
        description:
          "智能聚合软件引擎，为定制自定义区块链策略提供需求聚合服务，其实际用例有 ChainRunner Q —— 预构区块链场景的自动化DeFi聚合器。",
        link: "https://q.chainrunner.io/",
      },
      {
        title: "Biport",
        subTitle: "Something Amazing",
        description:
          "Chrome扩展应用形式的去中心化加密钱包。可桥接多有DApp、DeFi、NFT及GameFi的多练网络。与BiFi齐用时体验最佳。",
        link: "https://biport.io/",
      },
    ],
  },
  media: {
    header: {
      title: "Media",
      description:
        "you can find us on media \n we're at the center of the Defi platform",
    },
  },
  join: {
    header: {
      title: "Careers",
      description: "email to hello@pilab.co",
    },
  },
  footer: {
    email: "info@pilab.co",
    address: {
      seoul:
        "Seoul address: 5, Seongsuil-ro 8-gil, Seongdong-gu, Seoul, Republic of Korea",
      singapore:
        "Singapore address: 16 Collyer Quay #17-00 Income Raffles, Singapore",
    },
    bifrostpaper: "BIFROST White Paper",
    bifipaper: "BiFi White Paper",
    copyright: "Copyright {{0}} PiLab Technology",
  },
  more: "→ 了解更多",
};

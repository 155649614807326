import React, { useState } from "react";
import styled from "styled-components";
import { Language } from "@mui/icons-material";
import logo from "assets/images/logo.svg";
import Logo from "./Logo";
import DropDown from "./DropDown";
import Btn from "./Btn";
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from "config/mediaQuery";
import { useTranslation } from "react-i18next";

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  max-width: 1440px;
  padding-top: 40px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
`;

const MenuItem = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: white;
  margin: 0 25px;
  opacity: 0.35;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    display: none;
  }
`;

const LanguageWrapper = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AppHeader: React.FC = () => {
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const [openLanguage, setOpenLanguage] = useState(false);

  const onScroll = (id: string) => {
    const mainScrollView = document.getElementById(id);
    console.log(mainScrollView);
    if (mainScrollView) {
      mainScrollView.scrollIntoView({ behavior: "smooth" });
    }
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang).then();
    localStorage.setItem("language", lang);
  };

  return (
    <Root>
      <Wrapper>
        <Logo width="200px" src={logo} />
      </Wrapper>
      <Wrapper>
        <MenuItem onClick={() => onScroll("products-scroll-view")}>
          {t("header.products")}
        </MenuItem>
        <MenuItem onClick={() => onScroll("media-scroll-view")}>
          {t("header.media")}
        </MenuItem>
        <MenuItem onClick={() => onScroll("join-scroll-view")}>
          {t("header.join")}
        </MenuItem>
        <DropDown
          value={openLanguage}
          anchor={
            <Btn
              icon
              disabled={openLanguage}
              onClick={() => setOpenLanguage(true)}
            >
              <Language sx={{ opacity: 0.35 }} />
            </Btn>
          }
          onContentClick={() => setOpenLanguage(false)}
          onClickOutside={() => setOpenLanguage(false)}
        >
          <LanguageWrapper>
            <Btn onClick={() => changeLanguage("en")}>EN</Btn>
            <Btn onClick={() => changeLanguage("ko")}>KR</Btn>
            <Btn onClick={() => changeLanguage("cn")}>CN</Btn>
          </LanguageWrapper>
        </DropDown>
      </Wrapper>
    </Root>
  );
};

export default AppHeader;

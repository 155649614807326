import React from "react";
import { Card, CardMedia, CardContent } from "@mui/material";
import styled from "styled-components";
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from "config/mediaQuery";
import transition from "plugins/styles/transition";
import useIsMobile from "plugins/useIsMobile";
import Link from "components/Link";

interface MediaCardProps {
  articleUrl: string;
  imgUrl: any;
  title: string;
  date: string;
}

const CardTitle = styled.div`
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 50px;
  ${transition}

  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    font-size: 15px;
  }
`;
const CardDescription = styled.div`
  margin-top: 20px;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  ${transition}

  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    font-size: 13px;
  }
`;

const StyledLink = styled(Link)`
  img {
    transform: translateY(-3px);
  }
`;

const MediaCard: React.FC<MediaCardProps> = ({
  imgUrl,
  title,
  date,
  articleUrl,
}) => {
  const isMobile = useIsMobile();

  return (
    <div>
      <Card
        sx={{
          width: isMobile ? 300 : 342,
          borderRadius: isMobile ? "15px" : "7px",
          height: isMobile ? 246 : 280,
          margin: "auto",
          mr: 3,
          my: 3,
        }}
      >
        <StyledLink href={articleUrl}>
          <CardMedia
            component="img"
            image={imgUrl}
            alt="No img"
            height="60.5%"
            sx={{ objectFit: "contain" }}
          />
        </StyledLink>
        <CardContent>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{date}</CardDescription>
        </CardContent>
      </Card>
    </div>
  );
};

export default MediaCard;

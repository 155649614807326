export const ko = {
  header: {
    products: "Products",
    media: "Media",
    join: "Careers",
  },
  about: {
    title:
      "DeFi, NFTs, GameFi를 아우르는 \n 블록체인 DApp 포트폴리오를 구축합니다",
    subTitle: "파이랩은 보안을 최우선으로 설계합니다.",
  },
  mission: {
    title: "Mission",
    subTitle:
      "BIFROST의 멀티체인 인프라를 통해 분산된 네트워크를 연결하여, 블록체인이 유연하고 다양하게 활용되는 것을 목표로 합니다.",
    description:
      "Pilab 팀은 블록체인 Mass Adoption 실현의 핵심이 보안의 안정성이 입증된 멀티체인 사용 사례의 실현, 즉 킬러 DApp 개발에 있다고 믿습니다.",
  },
  products: {
    header: {
      title: "Products",
      description: "we design something spectacular \n that we are planning",
    },
    body: [
      {
        title: "BIFROST",
        subTitle: "Something Amazing",
        description:
          "BIFROST는 멀티체인 DApp 개발을 위한 개발 툴을 제공합니다. 특정 네트워크에 제한된 개발 환경에서 벗어나 보안, 속도, 수수료 등 각 항목에 적합한 네트워크를 선택할 수 있는 유연한 환경 지원으로 킬러 DApp 구축을 돕습니다.",
        link: "https://thebifrost.io/",
      },
      {
        title: "BiFi",
        subTitle: "Something Amazing",
        description:
          "BiFi는 BIFROST기반 탈중앙화 멀티체인 DeFi 예금·대출 서비스입니다. 현재 이더리움, 바이낸스 스마트체인, 아발란체, 클레이튼 네트워크를 지원합니다. BiFi의 이더리움 네트워크는 Native 비트코인의 직접 예금 및 대출 또한 지원합니다.",
        link: "https://bifi.finance/",
      },
      {
        title: "BiFi X",
        subTitle: "Something Amazing",
        description:
          "BiFi X는 DeFi 최초 플래시론을 활용한 원클릭 멀티체인 레버리지 플랫폼입니다. 이자농사와 마진 트레이딩에서의 반복 작업을 제거할 뿐만 아니라 거래 수수료를 대폭 절감합니다.",
        link: "https://x.bifi.finance/",
      },
      {
        title: "ChainRunners",
        subTitle: "Something Amazing",
        description:
          "체인러너는 맞춤형 블록체인 시나리오 전략 구축을 위해 on-demand 통합을 제공하는 스마트 통합 소프트웨어 엔진입니다. 사용 사례로는 자동화 시나리오 DeFi Aggregator인 ChainRunner Q 등이 있습니다.",
        link: "https://q.chainrunner.io/",
      },
      {
        title: "Biport",
        subTitle: "Something Amazing",
        description:
          "통합 멀티체인 탈중화 지갑 바이포트(Biport)는 모든 DApp, DeFi, NFT, GameFi에서 멀티체인 네트워크를 지원합니다. BiFi 사용에 가장 최적화된 지갑입니다.",
        link: "https://biport.io/",
      },
    ],
  },
  media: {
    header: {
      title: "Media",
      description:
        "you can find us on media \n we're at the center of the Defi platform",
    },
  },
  join: {
    header: {
      title: "Careers",
      description: "email to hello@pilab.co",
    },
  },
  footer: {
    email: "info@pilab.co",
    address: {
      seoul:
        "Seoul address: 5, Seongsuil-ro 8-gil, Seongdong-gu, Seoul, Republic of Korea",
      singapore:
        "Singapore address: 16 Collyer Quay #17-00 Income Raffles, Singapore",
    },
    bifrostpaper: "BIFROST White Paper",
    bifipaper: "BiFi White Paper",
    copyright: "Copyright {{0}} PiLab Technology",
  },
  more: "→ 더 알아보기",
};

import React from "react";
import styled from "styled-components";

interface LinkProps {
  href: string;
  underline?: boolean;
  children?: React.ReactNode;
}

const Root = styled.a<{ underline?: boolean }>`
  position: relative;
  color: white;
  font-family: "Roboto", sans-serif;
  text-decoration: ${(props) => (props.underline ? "underline" : "none")};
`;

const Link: React.FC<LinkProps> = ({ href, children, underline, ...rest }) => {
  return (
    <Root href={href} underline={underline} {...rest}>
      {children}
    </Root>
  );
};

export default Link;

import React from "react";
import styled from "styled-components";
import transition from "plugins/styles/transition";
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from "config/mediaQuery";

interface BackgroundProps {
  color: string;
  skew: number;
  zIndex?: number;
}

const Root = styled.div<{ color: string; skew: number; zIndex?: number }>`
  ${(props) => (props.zIndex ? `z-index: ${props.zIndex};` : "")}
  position: absolute;
  left: 0;
  width: 100%;
  height: 500px;
  background: ${(props) => props.color};
  opacity: 0.85;
  transform: skew(0, ${(props) => props.skew}deg);
  ${transition}

  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    height: 500px;
  }
`;

const Background: React.FC<BackgroundProps> = ({ color, skew, zIndex }) => {
  return <Root color={color} skew={skew} zIndex={zIndex} />;
};

export default Background;

import React from "react";
import styled from "styled-components";
import transition from "plugins/styles/transition";
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from "config/mediaQuery";

interface LogoProps {
  width: string;
  top?: string;
  right?: string;
  src: string;
}

const Image = styled.img<{
  width: string;
  top?: string;
  right?: string;
}>`
  overflow: hidden;
  position: absolute;
  width: ${(props) => props.width};
  height: auto;
  ${transition}
  ${(props) => (props.top ? `top: ${props.top};` : "")}
  ${(props) => (props.right ? `right: ${props.right};` : "")}
  background-color: transparent;

  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    margin-top: 8px;
  }
`;

const Logo: React.FC<LogoProps> = ({ width, top, right, src }) => {
  return (
    <Image width={width} top={top} right={right} src={src} alt="No Image" />
  );
};

export default Logo;

import React from "react";
import styled from "styled-components";
import logo from "assets/images/logoBackground.svg";
import Logo from "components/Logo";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import SectionContainer from "components/SectionContainer";
import useIsMobile from "plugins/useIsMobile";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from "../../config/mediaQuery";

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const About: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <SectionContainer paddingTop={isMobile ? "70px" : "118px"}>
      <Logo width={isMobile ? "80%" : "1068.74px"} src={logo} right="0" />
      <TextWrapper>
        <Text size={isMobile ? "20px" : "60px"} align="right" weight="bold">
          {t("about.title")
            .split("\n")
            .map((line, lineIndex) => (
              <div key={lineIndex}>{line}</div>
            ))}
        </Text>
        <Text
          size={isMobile ? "14px" : "37px"}
          color="gray"
          align="right"
          weight="bold"
          marginTop={isMobile ? "36px" : "130px"}
        >
          {t("about.subTitle")
            .split("\n")
            .map((line, lineIndex) => (
              <div key={lineIndex}>{line}</div>
            ))}
        </Text>
      </TextWrapper>
    </SectionContainer>
  );
};

export default About;
